var scroll = 0;
var primary_navbar = jQuery('#primary-navbar');

jQuery(window).scroll(function() {
    scroll = jQuery(window).scrollTop();
    if (scroll > 200) {
        primary_navbar.addClass('bg-white shadow');
    } else {
        primary_navbar.removeClass('bg-white shadow');
    }
});
